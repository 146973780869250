import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRole } from 'storage/profile';
import { AppState } from 'storage/store';

import { useUser } from './user';

export const useRole = () => {
  const dispatch = useDispatch();
  const user = useUser();
  const role = useSelector((state: AppState) => state.profile.role);

  useEffect(() => {
    dispatch(setRole(user.data?.role ?? 'user'));
  }, [dispatch, user.data?.role]);

  return role;
};
