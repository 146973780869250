import { useUser } from 'entities/user';
import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOrganization } from 'storage/profile';
import { AppState } from 'storage/store';

import { useOnceByPredicate } from '../../utils/hooks/useOnceByPredicate';
import * as S from './OrganizationSelect.styled';

type Props = {
  className?: string;
};

export const OrganizationSelect: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const user = useUser();

  const options = useMemo(() => {
    if (user.data?.role !== 'superuser') {
      return;
    }

    const orgs = [{ id: -1, value: 'All', label: 'All' }];
    orgs.push(
      ...user.data.organizations.map((org) => ({
        id: org.id,
        value: `${org.displayName}`,
        label: org.displayName,
      })),
    );
    return orgs;
  }, [user]);

  const valueInStore = useSelector((state: AppState) => state.profile.organization);

  const currentValue = useMemo(() => {
    if (valueInStore) {
      return {
        id: valueInStore.id,
        value: `${valueInStore.name}`,
        label: valueInStore.name,
      };
    }
    if (user.data) {
      return {
        id: user.data.organization.id,
        value: `${user.data.organization.displayName}`,
        label: user.data.organization.displayName,
      };
    }
    return;
  }, [user, valueInStore]);

  const onSelect = useCallback((_: any, value: any) => {
    dispatch(setOrganization({ id: value.id, name: value.value }));
  }, []);

  const displayValue = useMemo(() => {
    if ((options?.length || 0) < 2) {
      return user.data?.organization?.displayName && ` - ${user.data?.organization.displayName}`;
    }

    return (
      <>
        {' - '}
        <S.Select onSelect={onSelect} options={options} value={currentValue} />
      </>
    );
  }, [options, onSelect, currentValue]);

  useOnceByPredicate(
    () => {
      if (currentValue) {
        dispatch(setOrganization({ id: currentValue.id.toString(), name: currentValue.value }));
      }
    },
    () => !!currentValue?.id,
  );

  return <S.Container className={props.className}>FI Portal {displayValue}</S.Container>;
};
