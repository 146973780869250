import { UserDTO, UserOrganizationDTO } from './user.dto.types';
import { User, UserOrganization, UserRole } from './user.types';

const mapOrganizationDto = (dto: UserOrganizationDTO): UserOrganization => ({
  id: dto.id,
  displayName: dto.display_name || dto.legal_name,
});

export const userDtoMapper = (dto: UserDTO): User => ({
  organization: mapOrganizationDto(dto.organization),
  organizations: (dto.organizations || []).map(mapOrganizationDto),
  role: dto.role as UserRole,
});
